const stacks = [
  {
    id: "H449R0",
    name: "Stack 1A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.2,
    voltageData: { min: 1, avg: 1.4, max: 1.9, runtime: 216 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H239T5",
    name: "Stack 1B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.9,
    voltageData: { min: 1, avg: 1.38, max: 2, runtime: 142 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H839R1",
    name: "Stack 2A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.2,
    voltageData: { min: 0.9, avg: 1.3, max: 1.7, runtime: 180 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H189B8",
    name: "Stack 2B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.9,
    voltageData: { min: 1.15, avg: 1.15, max: 1.75, runtime: 164 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H939Y0",
    name: "Stack 3A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.1,
    voltageData: { min: 0.82, avg: 1.04, max: 1.2, runtime: 184 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H343T6",
    name: "Stack 3B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.6,
    voltageData: { min: 1.6, avg: 1, max: 1.9, runtime: 154 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H738F3",
    name: "Stack 4A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 5.2,
    voltageData: { min: 0.85, avg: 1.1, max: 1.3, runtime: 199 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H201G7",
    name: "Stack 4B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.8,
    voltageData: { min: 1.2, avg: 1.4, max: 2, runtime: 200 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H462N9",
    name: "Stack 5A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 5.0,
    voltageData: { min: 1.05, avg: 1.3, max: 1.9, runtime: 180 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H504X4",
    name: "Stack 5B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.7,
    voltageData: { min: 1.1, avg: 1.38, max: 1.92, runtime: 179 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H923B7",
    name: "Stack 6A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.5,
    voltageData: { min: 0.9, avg: 1.26, max: 2, runtime: 140 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H112C7",
    name: "Stack 6B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.2,
    voltageData: { min: 0.8, avg: 1.46, max: 1.85, runtime: 189 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H035E6",
    name: "Stack 7A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 5,
    voltageData: { min: 1.1, avg: 1.36, max: 1.85, runtime: 190 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H491T4",
    name: "Stack 7B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.5,
    voltageData: { min: 1, avg: 1.36, max: 1.85, runtime: 170 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H928V3",
    name: "Stack 8A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.8,
    voltageData: { min: 1.1, avg: 1.18, max: 1.75, runtime: 150 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
  {
    id: "H574F0",
    name: "Stack 8B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.1,
    voltageData: { min: 0.75, avg: 1, max: 1.48, runtime: 190 },
    anodeOutletTemperature: 80,
    anodeOutletPressure: 4,
    cathodeOutletTemperature: 73,
    cathodeOutletPressure: 30,
  },
];

export default stacks;
