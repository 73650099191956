import React from "react";
import ChartCard from "containers/StackDashboard/components/ChartCard/ChartCard";
import { generateNoisyLineData } from "utils/generate-data";
import styles from "./StackVoltage.module.less";

export default function StackVoltage({ stacks, substacks }) {
  const mode = substacks?.length ? "SUBSTACK" : "STACK";

  let items = stacks;
  if (substacks?.length) {
    items = substacks;
  }

  const data = items.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Item-${s.id}`,
      minY: 0.4 + 0.022 * idx,
      maxY: 1 + 0.022 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: mode === "STACK" ? s.id : `SS${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  const tempData = items.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Item-${s.id}`,
      minY: 19 + 0.12 * idx,
      maxY: 19.1 + 0.12 * idx,
      noiseAmount: 0.3,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: mode === "STACK" ? s.id : `SS${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  const pressureData = items.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Item-${s.id}`,
      minY: 38 + 0.12 * idx,
      maxY: 38.1 + 0.12 * idx,
      noiseAmount: 0.4,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: mode === "STACK" ? s.id : `SS${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  const totalVoltageData = items.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Item-${s.id}`,
      minY: mode === "STACK" ? 500 + 2 * idx : 190 + 0.1 * idx,
      maxY: mode === "STACK" ? 550 + 2 * idx : 197 + 0.1 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: mode === "STACK" ? s.id : `SS${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  const lflData = items.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Item-${s.id}`,
      minY: 1.8 + 0.022 * idx,
      maxY: 3.7 + 0.022 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: mode === "STACK" ? s.id : `SS${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  const maxVoltageTitle = mode === "STACK" ? "Total Stack Voltage" : "Total Substack Voltage";

  return (
    <div className={`${styles.container} ${mode}`}>
      <ChartCard data={data} title="Max Cell Voltage" yAxisRange={[0, 2]} units={["V"]} expandable />
      <ChartCard data={data} title="Min Cell Voltage" yAxisRange={[0, 2]} units={["V"]} expandable />
      <ChartCard data={data} title="Avg Cell Voltage" yAxisRange={[0, 2]} units={["V"]} expandable />
      <ChartCard
        data={totalVoltageData}
        title={maxVoltageTitle}
        yAxisRange={mode === "STACK" ? [500, 600] : [185, 200]}
        units={["V"]}
        expandable
        marginLeft={40}
      />
      <ChartCard data={data} title="Standard Deviation" yAxisRange={[0, 2]} units={["V"]} expandable />
      {mode === "STACK" && (
        <>
          <ChartCard data={lflData} title="LFL %" yAxisRange={[0, 5]} units={["%"]} expandable />
          <ChartCard data={tempData} title="DT" yAxisRange={[16, 24]} units={["°C"]} expandable marginLeft={38} />
          <ChartCard data={pressureData} title="DP" yAxisRange={[37, 41]} units={["barg"]} expandable marginLeft={62} />
        </>
      )}
    </div>
  );
}
