import React from "react";
import ChartCard from "containers/StackDashboard/components/ChartCard/ChartCard";
import { generateNoisyLineData } from "utils/generate-data";
import styles from "./CellView.module.less";

export default function CellView() {
  const data = Array.from({ length: 300 }).map((_, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `seed-${idx}`,
      minY: 0.1 + 0.004 * idx,
      maxY: 0.6 + 0.004 * idx,
      numPoints: 20,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: `Cell ${(idx + 1).toString().padStart(2, "0")}`,
      line: {
        width: 1,
      },
    };
  });

  return (
    <div className={`${styles.container}`}>
      <ChartCard data={data} title="Cell Voltage" yAxisRange={[0, 2]} units={["V"]} expandable responsive={false} />
    </div>
  );
}
