import ResponsivePlot from "components/ResponsivePlot";
import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker, Button, Select } from "antd";
import { ReactComponent as RefreshIcon } from "assets/refresh-icon.svg";

import { generateNoisyLineData } from "utils/generate-data";

import styles from "./Crossover.module.less";

const { RangePicker } = DatePicker;

const defaultHistoricalRange = [dayjs().subtract(24, "hour"), dayjs()];

const refreshOptions = [
  {
    label: "Refresh disabled",
    value: 0,
  },
  {
    label: "5 sec",
    value: 5000,
  },
  {
    label: "15 sec",
    value: 15000,
  },
  {
    label: "min",
    value: 60000,
  },
  {
    label: "5 min",
    value: 300000,
  },
];

const historicalPresets = [
  {
    label: "Last 24 Hours",
    value: defaultHistoricalRange,
  },
  {
    label: "Last 7 Days",
    value: [dayjs().subtract(7, "day"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().subtract(30, "day"), dayjs()],
  },
];

const layout = {
  xaxis: {
    title: "# Start Stop Cycles Completed",
  },
  yaxis: {
    title: "Crossover [H2 in O2, %LFL]",
    range: [0, 15],
    ticksuffix: " %",
  },
  margin: { t: 5 },
};

const lflLayout = {
  title: "Change in %LFL Over Time",
  xaxis: {
    title: "Time",
    type: "date",
  },
  yaxis: {
    title: "dLFL/dT",
    range: [2, 6.8],
    ticksuffix: " %",
  },
};

const sampleX = [
  8, 12, 20, 22, 25, 28, 29, 34, 40, 48, 58, 60, 66, 75, 77, 84, 85, 86, 99, 105, 106, 107, 109, 111, 113,
];

const sampleY = [
  1.77, 1.77, 1.772, 1.773, 1.772, 1.772, 1.772, 1.773, 1.772, 1.772, 1.772, 1.773, 1.772, 1.772, 1.772, 1.772, 1.773,
  1.773, 1.774, 1.775, 1.73, 1.776, 1.774, 1.77, 1.772,
];

const startDate = dayjs();

const timeUnit = "minute";

const dateValues = sampleX.map((x) => startDate.add(x, timeUnit).toDate());

const dateSteps = [];
for (let i = 1; i < dateValues.length; i++) {
  const diffMs = dateValues[i].getTime() - dateValues[i - 1].getTime();
  dateSteps.push(diffMs);
}

const xVals = [...dateValues];
let currentDateValue = xVals[xVals.length - 1];

for (let i = 0; i < sampleX.length * 3; i++) {
  const nextDateStep = dateSteps[i % dateSteps.length];
  currentDateValue = new Date(currentDateValue.getTime() + nextDateStep);
  xVals.push(currentDateValue);
}

const minY = Math.min(...sampleY);
const maxY = Math.max(...sampleY);
const currentRange = maxY - minY;

const desiredRange = 3.0;
const desiredMin = 2.5;

const scaledSampleY = sampleY.map((y) => {
  const normalized = (y - minY) / currentRange;
  return normalized * desiredRange + desiredMin;
});

const yVals = [...scaledSampleY];
const ySteps = [];

for (let i = 1; i < scaledSampleY.length; i++) {
  ySteps.push(scaledSampleY[i] - scaledSampleY[i - 1]);
}

let currentYValue = yVals[yVals.length - 1];
for (let i = 0; i < scaledSampleY.length * 3; i++) {
  const nextYStep = ySteps[i % ySteps.length];
  currentYValue += nextYStep;
  yVals.push(currentYValue);
}

export default function Crossover({ stacks }) {
  const [selectedRefresh, setSelectedRefresh] = useState(5000);
  const [selectedCrossoverVal, setSelectedCrossoverVal] = useState(1);

  const crossoverData = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      randomStep: true,
      seed: `Stack-${s.id}`,
      maxX: 30,
      minY: 3 + 0.12 * idx,
      maxY: 8.4 + 0.12 * idx,
      numPoints: 8,
    });
    return {
      x: xValues,
      y: yValues,
      mode: "markers",
      type: "scatter",
      name: s.id,
      marker: {
        size: 12,
      },
    };
  });

  const crossoverData2 = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      randomStep: true,
      seed: `Stack-${s.id}2`,
      maxX: 30,
      minY: 3 + 0.12 * idx,
      maxY: 8.4 + 0.12 * idx,
      numPoints: 8,
    });
    return {
      x: xValues,
      y: yValues,
      mode: "markers",
      type: "scatter",
      name: s.id,
      marker: {
        size: 12,
      },
    };
  });

  const lflData = stacks.map((s, idx) => {
    const yValsI = yVals.map((v) => v + 0.04 * idx);
    return {
      x: xVals,
      y: yValsI,
      type: "scatter",
      mode: "line",
      name: s.id,
      line: {
        width: 1,
      },
    };
  });

  const selectedCrossoverData = (val) => {
    switch (selectedCrossoverVal) {
      case 1:
        return crossoverData;
      case 6.72:
        return crossoverData2;
      default:
        return crossoverData;
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.section} ${styles.plotsContainer}`}>
        <div className={styles.plotContainer}>
          <div className={styles.rangePicker} style={{ width: 360 }}>
            <RangePicker showTime defaultValue={defaultHistoricalRange} presets={historicalPresets} />
          </div>
          <div className={styles.plot}>
            <ResponsivePlot
              data={lflData}
              layout={lflLayout}
              config={{
                modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
                displaylogo: false,
                responsive: true,
              }}
            />
          </div>
        </div>
        <div className={styles.plotContainer}>
          <Select
            value={selectedCrossoverVal}
            style={{ alignSelf: "center", width: 260 }}
            options={[
              { value: 1, label: "Crossover at 1A/Cm2" },
              { value: 6.72, label: "Crossover at 6.72A/Cm2" },
            ]}
            onChange={(newVal) => setSelectedCrossoverVal(newVal)}
          />
          <ResponsivePlot
            data={selectedCrossoverData(selectedCrossoverVal)}
            layout={layout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.card}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Global dLFL/dt</div>
            <div className={styles.refreshContainer}>
              <Button shape="circle" className={styles.circleButton}>
                <RefreshIcon />
              </Button>
              <Select
                className={styles.refreshSelector}
                value={selectedRefresh}
                onChange={(newRefreshVal) => setSelectedRefresh(newRefreshVal)}
                options={refreshOptions.map((o) => ({
                  value: o.value,
                  label: o.value === 0 ? o.label : `Refresh every ${o.label}`,
                }))}
              />
            </div>
          </div>

          <div className={styles.content}>
            {stacks.map((s) => (
              <div key={s.id} className={styles.stackContainer}>
                <div>
                  <div className={styles.name}>{s.name}</div>
                  <div className={styles.id}>{s.id}</div>
                </div>
                <div className={styles.listItem}>
                  <div className={styles.val}>{`${s.lfl}%`} </div>
                  <div className={styles.unit}>LFL</div>
                </div>
                <div className={styles.listItem}>
                  <div className={styles.val}>{s.eol} </div>
                  <div className={styles.unit}>Hrs to Limit</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
