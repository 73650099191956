/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as StackIcon } from "assets/stack-detailed.svg";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Checkbox } from "antd";
import { ReactComponent as CarouselArrowLeft } from "assets/carousel-arrow-left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/carousel-arrow-right.svg";

import stacks from "containers/StackDashboard/stackData";

import styles from "./GridView.module.less";

export default function GridView({ viewMode }) {
  const navigate = useNavigate();
  const isBigScreen = useMediaQuery({ minWidth: 1900 });

  const arrowSize = isBigScreen ? 26 : 20;

  const groupedStacks = Array.from({ length: 4 }, (_, i) => stacks.slice(i * 4, i * 4 + 4));

  const StackCard = ({ s, stackIdx, groupIdx }) => (
    <div
      key={s.id}
      className={`${styles.stackCard} ${stackIdx < 2 ? styles.groupA : styles.groupB}`}
      onClick={() => navigate(`/stack-dashboard/stacks/${groupIdx * 4 + stackIdx + 1}?view=${viewMode}`)}
    >
      <div className={styles.header}>
        <div className={styles.headerSection}>
          <StackIcon width={20} heigth={20} />
          <div>{s.name}</div>
        </div>
        <div className={styles.headerSection}>
          ID <div className={styles.displayField}>{s.id}</div>
        </div>
      </div>
      <div className={styles.content}>
        <Carousel
          className={styles.carousel}
          showIndicators={false}
          showStatus={false}
          renderArrowPrev={(clickHandler, hasPrev) =>
            hasPrev && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickHandler();
                }}
                className={styles.arrowLeft}
              >
                <CarouselArrowLeft height={arrowSize} width={arrowSize} />
              </div>
            )
          }
          renderArrowNext={(clickHandler, hasNext) =>
            hasNext && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  clickHandler();
                }}
                className={styles.arrowRight}
              >
                <CarouselArrowRight height={arrowSize} width={arrowSize} />
              </div>
            )
          }
        >
          <div className={styles.carouselItem}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={`${styles.listContainer} ${styles.listLeft}`}>
                <div className={styles.listItem}>
                  <div>Max Cell V</div> <div className={styles.displayField}>1.96</div>
                </div>
                <div className={styles.listItem}>
                  <div>Avg Cell V</div> <div className={styles.displayField}>1.92</div>
                </div>
                <div className={styles.listItem}>
                  <div>Min Cell V</div> <div className={styles.displayField}>1.89</div>
                </div>
                <div className={styles.listItem}>
                  <div>Std. Dev (V)</div> <div className={styles.displayField}>1</div>
                </div>
              </div>
              <div className={styles.listContainer}>
                <div className={styles.listItem}>
                  <div>Total V</div> <div className={styles.displayField}>576</div>
                </div>
                <div className={styles.listItem}>
                  <div>LFL%</div> <div className={styles.displayField}>3%</div>
                </div>
                <div className={styles.listItem}>
                  <div>Total Bolt Ld</div> <Checkbox checked />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.carouselItem}>
            <div className={styles.listContainer}>
              <div className={styles.listItem}>
                <div>Anode Outlet Temp</div> <div className={styles.displayField}>{s.anodeOutletTemperature}</div>
              </div>
              <div className={styles.listItem}>
                <div>Anode Outlet Pressure</div> <div className={styles.displayField}>{s.anodeOutletPressure}</div>
              </div>
              <div className={styles.listItem}>
                <div>Cathode Outlet Temp</div> <div className={styles.displayField}>{s.cathodeOutletTemperature}</div>
              </div>
              <div className={styles.listItem}>
                <div>Cathode Outlet Pressure</div> <div className={styles.displayField}>{s.cathodeOutletPressure}</div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );

  return (
    <div className={styles.gridOuter}>
      {groupedStacks.map((stackGroup, idx) => (
        <div key={`stack-group-${stackGroup[0].id}`} className={styles.enclosure}>
          <div className={`${styles.enclosureTag} ${styles.tag}`}>
            <div> Enclosure {idx + 1}</div>
            <div>
              <span className={styles.highlightVal}>5% </span>
              <span>LFL</span>
            </div>
          </div>
          <div className={`${styles.stackTagLeft} ${styles.tag}`}>
            Current {2 * (idx + 1) - 1}{" "}
            <div>
              <span className={styles.highlightVal}>17600 </span>
              <span>A</span>
            </div>
          </div>
          <div className={`${styles.stackTagRight} ${styles.tag}`}>
            Current {2 * (idx + 1)}{" "}
            <div>
              <span className={styles.highlightVal}>17600 </span>
              <span>A</span>
            </div>
          </div>
          <div className={styles.stackGroup}>
            <div className={styles.stringBox}>
              {stackGroup.slice(0, 2).map((s, stackIdx) => (
                <StackCard key={s.id} s={s} groupIdx={idx} stackIdx={stackIdx} />
              ))}
            </div>
            <div className={styles.stringBox}>
              {stackGroup.slice(2, 4).map((s, stackIdx) => (
                <StackCard key={s.id} s={s} groupIdx={idx} stackIdx={stackIdx} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
