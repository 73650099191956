/* eslint-disable import/no-extraneous-dependencies */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ReactDOM from "react-dom";
import PlotlyBase from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import { scaleTime } from "d3-scale";

import { OFFSETS } from "config/plotConfig";

import {
  annotationItems,
  annotationShapes,
  bandShapes,
  convertToLocalTime,
  exportCsv,
  maxLineShapes,
  minLineShapes,
  nameString,
  singleEquipment,
  getAxesProps,
  getAssignedAxis,
} from "./helpers/plot.helpers";

import Event from "./components/Event/Event";

import styles from "./Plot.module.less";

const Plotly = createPlotlyComponent(PlotlyBase);

export default function Plot({
  name,
  traces,
  annotations = [],
  thresholds,
  events = [],
  time,
  zoomRange,
  showLegend,
  legendConfig = {},
  rangeSlider,
  width,
  height,
  className,
  style,
  onRelayout = () => {},
  onClick,
  onClickAnnotation,
  showResolvedAnnotations = false,
  customAxes = [],
}) {
  const plot = useRef();
  const ghostPlot = useRef();
  const [searchParams] = useSearchParams();
  const annotationId = searchParams.get("annotationId");
  const webgl = searchParams.get("webgl");
  const [tracesVisibility, setTracesVisibility] = useState([]);
  const [relayouting, setRelayouting] = useState(false);
  const [legendClickTimer, setLegendClickTimer] = useState(null);

  const traceCount = traces?.length;

  useEffect(() => {
    if (traceCount) {
      // console.log("Traces: ", traces);
      setTracesVisibility(traces.map((trace) => ({ name: nameString(trace), visible: true })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traceCount]);

  const [positioning, setPositioning] = useState(undefined);

  const getPosition = () => {
    const plotContainerSize = plot.current?.el.getBoundingClientRect();
    const plotSize = plot.current?.el.getElementsByClassName("bglayer")[0]?.getBoundingClientRect();
    setPositioning({
      left: plotSize.left - plotContainerSize.left,
      top: plotSize.top - plotContainerSize.top,
      width: plotContainerSize.width,
      height: plotSize.height,
    });
  };

  const [retry, setRetry] = useState(0);
  const retryInterval = useRef(null);

  useEffect(() => {
    const plotContainer = plot.current.el;
    const plotBackgroundContainer = plot.current?.el.getElementsByClassName("bglayer")[0];

    if (!plotContainer || !plotBackgroundContainer) return;

    const observer = new ResizeObserver((entries) => {
      entries.forEach(() => {
        getPosition();
      });
    });
    observer.observe(plotContainer);
    observer.observe(plotBackgroundContainer);
    clearInterval(retryInterval.current);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(plotContainer);
      observer.unobserve(plotBackgroundContainer);
    };
  }, [retry]);

  useEffect(() => {
    retryInterval.current = setInterval(() => {
      setRetry((prev) => prev + 1);
    }, 250);
    return () => clearInterval(retryInterval.current);
  }, []);

  const x = useMemo(
    () =>
      scaleTime()
        .domain([new Date(zoomRange.from), new Date(zoomRange.to)])
        .range([OFFSETS.LEFT, positioning?.width]),
    [zoomRange.from, zoomRange.to, positioning?.width]
  );

  const relayoutSleep = useRef(null);
  const handleEventRelayout = () => {
    setRelayouting(true);
    if (relayoutSleep.current) clearTimeout(relayoutSleep.current);
    relayoutSleep.current = setTimeout(() => {
      setRelayouting(false);
    }, 500);
  };

  const handleRelayout = (eventData) => {
    handleEventRelayout();
    onRelayout(eventData);
  };

  // eslint-disable-next-line consistent-return
  const handleLegendClick = (eventData) => {
    if (legendClickTimer) {
      clearTimeout(legendClickTimer);
      setLegendClickTimer(null);
      return false;
    }

    const newTimer = setTimeout(() => {
      const clickedIndex = eventData.curveNumber;
      if (clickedIndex === undefined) return false;

      setTracesVisibility((prev) => {
        const isOnlyThisTraceVisible = prev.every((v, i) => (i === clickedIndex ? v.visible : !v.visible));

        if (isOnlyThisTraceVisible) {
          return prev.map(() => ({ visible: true }));
        }
        return prev.map((v, i) => ({
          visible: i === clickedIndex,
        }));
      });
      setLegendClickTimer(null);
      return false;
    }, 300);

    setLegendClickTimer(newTimer);
  };

  const handleLegendDoubleClick = (eventData) => {
    if (legendClickTimer) {
      clearTimeout(legendClickTimer);
      setLegendClickTimer(null);
    }
    const index = eventData.curveNumber;
    if (index === undefined) return false;
    setTracesVisibility((prev) => {
      const newVisibility = [...prev];
      newVisibility[index].visible = !prev[index].visible;
      return newVisibility;
    });
    return false;
  };

  const [renderedThresholds, setRenderedThresholds] = useState([]);

  useEffect(() => {
    setRenderedThresholds(
      thresholds.filter((threshold) => tracesVisibility.find((trace) => trace.name === threshold.measureID)?.visible)
    );
  }, [thresholds, tracesVisibility]);

  const GhostPlot = forwardRef((props, ref) => {
    const canvasEl = useRef();

    useImperativeHandle(ref, () => ({
      screenshot: async () => {
        const element = canvasEl.current;
        const canvas = await html2canvas(element, { logging: true });
        const dataURL = canvas.toDataURL("image/png");
        downloadjs(dataURL, `${name}.png`, "image/png");
      },
    }));

    return ReactDOM.createPortal(
      <div ref={canvasEl} style={{ position: "fixed", pointerEvents: "none" }}>
        <Plotly
          data={[
            traces?.map((trace) => ({
              type: "scatter",
              mode: "lines",
              legendgroup: !singleEquipment(traces) && trace.equipment.id,
              legendgrouptitle: {
                text: !singleEquipment(traces) && trace.equipment.name,
              },
              text: trace?.measure?.unit || "",
              hoverinfo: "name+x+y+text",
              name: nameString(trace),
              x: trace.x,
              y: trace.y,
              yaxis: getAssignedAxis(trace, customAxes),
            })),
          ].flat()}
          layout={{
            title: name,
            width: 1024,
            height: 1024,
            shapes: [
              ...minLineShapes({ thresholds, traces }),
              ...maxLineShapes({ thresholds, traces }),
              ...bandShapes({ thresholds, traces }),
            ],
            margin: {
              t: 50,
              r: 0,
              b: 30,
              l: 35,
            },
            paper_bgcolor: "#FFF",
            xaxis: {
              type: "date",
              range: [convertToLocalTime(zoomRange.from || time.from), convertToLocalTime(zoomRange.to || time.to)],
              ticklabelposition: "outside left",
              automargin: true,
            },
            ...getAxesProps(customAxes),
            legend: {
              orientation: "v",
              x: 1,
              y: 0.5,
              xref: "container",
            },
            showlegend: true,
          }}
          config={{
            modeBarButtons: [[]],
            displaylogo: false,
            responsive: true,
            doubleClick: "autosize",
          }}
        />
      </div>,
      document.body
    );
  });

  return (
    <>
      <div className={`${styles.plotContainer} ${className || ""}`}>
        <Plotly
          ref={plot}
          className={styles.plot}
          data={[
            traces
              ?.sort((a, b) => nameString(a).localeCompare(nameString(b), "en", { numeric: true }))
              ?.map((trace, i) => ({
                type: webgl === "true" ? "scattergl" : "scatter",
                mode: "lines",
                legendgroup: !singleEquipment(traces) && trace.equipment.id,
                legendgrouptitle: {
                  text: !singleEquipment(traces) && trace.equipment.name,
                },
                text: trace?.measure?.unit || "",
                hoverinfo: "name+x+y+text",
                hoverlabel: { namelength: -1 },
                name: nameString(trace),
                x: trace.x,
                y: trace.y,
                yaxis: getAssignedAxis(trace, customAxes),
                line: {
                  width: 1,
                },
                visible: tracesVisibility.length > 0 && tracesVisibility[i]?.visible ? true : "legendonly",
              })),
          ].flat()}
          layout={{
            width,
            height,
            shapes: [
              ...minLineShapes({ thresholds: renderedThresholds, traces }),
              ...maxLineShapes({ thresholds: renderedThresholds, traces }),
              ...bandShapes({ thresholds: renderedThresholds, traces }),
              ...annotationShapes({ annotations, showResolved: showResolvedAnnotations, annotationId }),
            ],
            margin: {
              t: OFFSETS.TOP,
              r: OFFSETS.RIGHT,
              b: OFFSETS.BOTTOM,
              l: OFFSETS.LEFT,
              pad: 5,
            },
            paper_bgcolor: "#FFF",
            hovermode: traceCount > 20 ? "closest" : "x unified",
            xaxis: {
              type: "date",
              range: [convertToLocalTime(zoomRange.from || time.from), convertToLocalTime(zoomRange.to || time.to)],
              ticklabelposition: "outside bottom",
              ticklabeloverflow: "allow",
              rangeslider: rangeSlider && {
                thickness: 0.1,
              },
              automargin: false,
            },
            ...getAxesProps(customAxes),
            annotations: annotationItems({ annotations, showResolved: showResolvedAnnotations, annotationId }),
            legend: {
              orientation: "v",
              groupclick: "toggleitem",
              y: 1,
              yanchor: "bottom",
              itemclick: false,
              itemdoubleclick: false,
              ...legendConfig,
            },
            showlegend: showLegend && showLegend,
          }}
          config={{
            modeBarButtons: [
              ["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"],
              [
                {
                  name: "downloadPng",
                  title: "Download plot as PNG",
                  icon: {
                    width: 857.1,
                    height: 1000,
                    path: "M864 248H728l-32.4-90.8a32.07 32.07 0 00-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z",
                  },
                  click: () => ghostPlot.current.screenshot(),
                },
                {
                  name: "downloadCsv",
                  title: "Download data as csv",
                  icon: {
                    width: 857.1,
                    height: 1000,
                    path: "m214-7h429v214h-429v-214z m500 0h72v500q0 8-6 21t-11 20l-157 156q-5 6-19 12t-22 5v-232q0-22-15-38t-38-16h-322q-22 0-37 16t-16 38v232h-72v-714h72v232q0 22 16 38t37 16h465q22 0 38-16t15-38v-232z m-214 518v178q0 8-5 13t-13 5h-107q-7 0-13-5t-5-13v-178q0-8 5-13t13-5h107q7 0 13 5t5 13z m357-18v-518q0-22-15-38t-38-16h-750q-23 0-38 16t-16 38v750q0 22 16 38t38 16h517q23 0 50-12t42-26l156-157q16-15 27-42t11-49z",
                    transform: "matrix(1 0 0 -1 0 850)",
                  },
                  click: (gd) => exportCsv({ gd, name }),
                },
              ],
            ],
            displaylogo: false,
            responsive: true,
            doubleClick: "autosize",
          }}
          style={style}
          onRelayout={handleRelayout}
          onClick={onClick}
          onClickAnnotation={onClickAnnotation}
          onLegendClick={handleLegendClick}
          onLegendDoubleClick={handleLegendDoubleClick}
        />
        {!relayouting &&
          positioning &&
          events
            .filter((event) => new Date(event.time) <= new Date(zoomRange.to))
            .map((event) => (
              <Event
                key={`${event.equipmentSlug}-${event.type}-${event.time}`}
                event={event}
                position={x(new Date(event.time))}
                size={positioning}
              />
            ))}
      </div>
      <GhostPlot ref={ghostPlot} />
    </>
  );
}
