import React from "react";
import ChartCard from "containers/StackDashboard/components/ChartCard/ChartCard";
import { generateNoisyLineData } from "utils/generate-data";

import styles from "./OtherTrends.module.less";

export default function OtherTrends({ stacks }) {
  const data = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Stack-${s.id}`,
      minY: 0.4 + 0.022 * idx,
      maxY: 1 + 0.022 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: s.id,
      line: {
        width: 1,
      },
    };
  });

  const lineData = (name, minY = 0.3, maxY = 0.9, numPoints = 30) => {
    const lineVals = generateNoisyLineData({
      datetime: true,
      seed: name,
      minY,
      maxY,
      numPoints,
    });

    return {
      x: lineVals.xValues,
      y: lineVals.yValues,
      type: "scatter",
      mode: "line",
      name,
      line: {
        width: 1,
      },
    };
  };

  const pressureData = [
    "Anode Outlet Pressure",
    "Cathode Outlet Pressure",
    "Anode Inlet Pressure",
    "Cathode Inlet Pressure",
  ].map((name, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: name,
      minY: 2 + 6 * idx,
      maxY: 19 + 6 * idx,
      numPoints: 20,
    });

    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name,
      line: {
        width: 1,
      },
    };
  });

  const lflData = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Stack-${s.id}`,
      minY: 1.2 + 0.08 * idx,
      maxY: 2.5 + 0.08 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: `S${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  const enData = ["En 1", "En 2", "En 3", "En 4"].map((name, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: name,
      minY: 2.5 + 0.08 * idx,
      maxY: 3.5 + 0.08 * idx,
      numPoints: 20,
    });

    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name,
      line: {
        width: 1,
      },
    };
  });

  return (
    <div className={styles.container}>
      <ChartCard
        data={[...data, lineData("Anode<br>Inlet Temp")]}
        title="Anode Temperature (C)"
        units={["C"]}
        yAxisRange={[0, 2]}
        expandable
        margin={{ t: 5, r: 25, b: 35, l: 36 }}
      />
      <ChartCard
        data={[...data, lineData("Cathode<br>Inlet Temp")]}
        title="Cathode Temperature (C)"
        units={["C"]}
        yAxisRange={[0, 2]}
        expandable
        margin={{ t: 5, r: 25, b: 35, l: 36 }}
      />
      <ChartCard
        data={data}
        title="Current Density (A/cm2)"
        yAxisRange={[0, 2]}
        expandable
        units={["A/cm2"]}
        margin={{ t: 5, r: 25, b: 35, l: 45 }}
      />
      <ChartCard
        data={[lineData("Anode Flow Rate", 3, 8)]}
        title="Anode Flow Rate (L/min)"
        yAxisRange={[0, 8.1]}
        units={["L/min"]}
        expandable
        margin={{ t: 5, r: 25, b: 35, l: 48 }}
      />
      <ChartCard
        data={pressureData}
        title="Pressure (barg)"
        yAxisRange={[0, 40]}
        units={["barg"]}
        expandable
        margin={{ t: 5, r: 25, b: 35, l: 50 }}
      />
      <ChartCard data={enData} title="LFL %" yAxisRange={[0, 4]} units={["%"]} expandable />
    </div>
  );
}
