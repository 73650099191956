import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { useUser } from "context/UserContext";
import { getModuleFromPathname } from "utils/module-utils";
import { useUpdateMyRecentModules } from "hooks/apiService";

import { Card } from "antd";

import Main from "containers/Main";
import NavLayout from "layouts/NavLayout";
import PrivateRoute from "routes/PrivateRoute";

import Account from "containers/Account";
import AddPanel from "containers/Dashboards/components/AddPanel";
import AlarmRules from "containers/AlarmRules";
import AlarmRule from "containers/AlarmRules/AlarmRule";
import AlarmDetails from "containers/AlarmRules/AlarmDetails";
import AlarmAcknowledgement from "containers/AlarmAcknowledgement";
import AssetMap from "containers/AssetMap";
import Authentication from "containers/Authentication";
import BITools from "containers/BITools";
import CMSDocument from "containers/CMSDocument";
import Dashboard from "containers/Dashboards/components/Dashboard";
import DashboardEdit from "containers/Dashboards/components/DashboardEdit";
import DashboardInput from "containers/Dashboards/components/DashboardInput";
import Dashboards from "containers/Dashboards";
import DataPartners from "containers/DataPartners";
import H2Export from "containers/H2Export";
import Home from "containers/Home";
import IncidentReports from "containers/IncidentReports";
import IncidentReport from "containers/IncidentReports/components/IncidentReport";
import IncidentReportSteps from "containers/IncidentReports/components/IncidentReportSteps";
import InviteUsers from "containers/RolesAndAdmin/components/InviteUsers";
import Notifications from "containers/Notifications";
import Member from "containers/RolesAndAdmin/components/Member";
import Overview from "containers/Overview";
import Page403 from "components/Page403";
import Page404 from "components/Page404";
import PageComingSoon from "components/PageComingSoon";
import Panel from "containers/Panel";
import PanelEdit from "containers/Panel/PanelEdit";
import RolesAndAdmin from "containers/RolesAndAdmin/RolesAndAdmin";
import SuperAdminConsole from "containers/SuperAdminConsole";
import WorkRequest from "containers/WorkRequests/WorkRequest";
import WorkRequests from "containers/WorkRequests";
import ServiceSupport from "containers/ServiceSupport";
import HelpCenter from "containers/HelpCenter";
import SystemOverview from "containers/SystemOverview";
import AlarmAnalytics from "containers/AlarmAnalytics";
import DocumentCenter from "containers/DocumentCenter";
import StackDashboard from "containers/StackDashboard";

function AppRoutes() {
  const { loading, hasPermissions } = useUser();
  const location = useLocation();
  const [updateMyRecentModules] = useUpdateMyRecentModules();
  const [mostRecentModule, setMostRecentModule] = useState();

  useEffect(() => {
    const module = getModuleFromPathname(location.pathname);
    if (module && module !== mostRecentModule) {
      setMostRecentModule(module);
      updateMyRecentModules({ module });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getAuthorizedRoute = ({ permission, global = false, ...rest }) => {
    const loadingProps = {
      ...rest,
      element: <Card loading />,
    };
    if (loading) {
      return <Route {...loadingProps} />;
    }
    if ((!global && hasPermissions([permission])) || hasPermissions([permission], { global: true })) {
      return <Route {...rest} />;
    }
    const unauthorizedProps = {
      ...rest,
      element: <Page403 />,
    };
    return <Route {...unauthorizedProps} />;
  };

  return (
    <Routes>
      <Route path="/login" element={<Authentication />} />
      <Route path="/privacy" element={<CMSDocument slug="privacy-notice" />} />
      <Route path="/terms-of-service" element={<CMSDocument slug="terms-of-service" />} />
      <Route
        exact
        path="/dashboards/:id"
        element={
          <NavLayout fullscreen hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Dashboard />} />
      </Route>
      <Route
        exact
        path="/overview"
        element={
          <NavLayout fullscreen hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Overview />} />
      </Route>
      {getAuthorizedRoute({
        path: "/business-intelligence/:tab",
        permission: "create:bi_dashboards",
        element: (
          <NavLayout fullscreen hasFooter={false}>
            <BITools />
          </NavLayout>
        ),
      })}
      <Route
        exact
        path="/home"
        element={
          <NavLayout fullscreen>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        exact
        path="/assets"
        element={
          <NavLayout fullscreen hasFooter>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<AssetMap allAssets />} />
        <Route exact path="/assets/shared" element={<AssetMap sharedAssets />} />
        <Route exact path="/assets/new" element={<AssetMap isNew />} />
        <Route exact path="/assets/:assetId">
          <Route index element={<AssetMap />} />
          <Route exact path="/assets/:assetId/edit" element={<AssetMap editing />} />
          <Route exact path="/assets/:assetId/share" element={<AssetMap sharing />} />
        </Route>
      </Route>
      <Route
        exact
        path="/dashboards"
        element={
          <NavLayout fullscreen>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Dashboards />} />
        <Route exact path="/dashboards/" element={<Dashboards />} />
        <Route exact path="/dashboards/system-overview" element={<Dashboards />} />
        <Route exact path="/dashboards/favorites" element={<Dashboards />} />
        <Route exact path="/dashboards/all" element={<Dashboards />} />
      </Route>
      <Route
        exact
        path="/alarm-rules"
        element={
          <NavLayout>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<AlarmRules />} />
        <Route exact path="/alarm-rules/new" element={<AlarmRule />} />
        <Route exact path="/alarm-rules/:alarmId/edit" element={<AlarmRule />} />
        <Route exact path="/alarm-rules/:alarmId" element={<AlarmDetails />} />
      </Route>
      <Route
        exact
        path="/alarm-acknowledgement"
        element={
          <NavLayout hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<AlarmAcknowledgement />} />
      </Route>
      <Route
        exact
        path="/electrolyzer-simulation"
        element={
          <NavLayout hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<H2Export />} />
      </Route>
      <Route
        exact
        path="/service-support"
        element={
          <NavLayout fullscreen hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<ServiceSupport />} />
      </Route>
      <Route
        exact
        path="/document-center"
        element={
          <NavLayout fullscreen>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<DocumentCenter />} />
      </Route>
      <Route
        exact
        path="/stack-dashboard"
        element={
          <NavLayout fullscreen>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<StackDashboard mode="STACK" />} />
        <Route exact path="/stack-dashboard/crossover">
          <Route index element={<StackDashboard mode="CROSSOVER" />} />
        </Route>
        <Route exact path="/stack-dashboard/iv-curve">
          <Route index element={<StackDashboard mode="IV_CURVE" />} />
        </Route>
        <Route exact path="/stack-dashboard/dv-dt">
          <Route index element={<StackDashboard mode="DV/DT" />} />
        </Route>
        <Route exact path="/stack-dashboard/stacks/:stackId">
          <Route index element={<StackDashboard mode="SUBSTACK" />} />
        </Route>
      </Route>
      <Route
        exact
        path="/notifications"
        element={
          <NavLayout fullscreen hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Notifications />} />
      </Route>
      <Route
        exact
        path="/help-center"
        element={
          <NavLayout fullscreen hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<HelpCenter />} />
      </Route>
      <Route
        exact
        path="/panels/:panelId"
        element={
          <NavLayout hasFooter={false}>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Panel />} />
      </Route>
      <Route
        path="/"
        element={
          <NavLayout>
            <PrivateRoute />
          </NavLayout>
        }
      >
        <Route index element={<Main />} />
        <Route path="dashboards/new" element={<DashboardInput mode="add" />} />
        <Route path="/dashboards/:id/edit" element={<DashboardEdit />} />
        <Route exact path="/dashboards/:id/add-panel" element={<AddPanel />} />
        <Route path="work-requests" element={<WorkRequests />} />
        <Route path="quickboard" element={<PageComingSoon />} />
        <Route path="system-overview" element={<SystemOverview />} />
        <Route path="alarm-analytics" element={<AlarmAnalytics />} />
        <Route path="alarm-analytics/:page" element={<AlarmAnalytics />} />
        <Route path="work-requests/:taskId" element={<WorkRequest />} />
        <Route path="panels/:panelId/edit" element={<PanelEdit />} />
        <Route path="account" element={<Account />} />
        {getAuthorizedRoute({
          path: "data-partners",
          permission: "read:org_data_partners",
          element: <DataPartners />,
        })}
        {getAuthorizedRoute({
          path: "roles-admin/:tab",
          permission: "read:all_roles",
          element: <RolesAndAdmin />,
        })}
        {getAuthorizedRoute({
          path: "roles-admin/members/:id",
          permission: "read:all_roles",
          element: <Member />,
        })}
        {getAuthorizedRoute({
          path: "roles-admin/add-members",
          permission: "read:all_roles",
          element: <InviteUsers />,
        })}
        {getAuthorizedRoute({
          path: "super-admin",
          permission: "create:organization_profiles",
          global: true,
          element: <SuperAdminConsole />,
        })}
        <Route path="incident-reports" element={<IncidentReports />} />
        <Route path="incident-reports/:id" element={<IncidentReport mode="view" />} />
        <Route path="incident-reports/:id/edit" element={<IncidentReport />} />
        <Route path="incident-reports/new" element={<IncidentReportSteps />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
