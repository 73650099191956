import React from "react";
import ChartCard from "containers/StackDashboard/components/ChartCard/ChartCard";
import { generateNoisyLineData } from "utils/generate-data";

import styles from "./StackTrend.module.less";

const data = ["Max V", "Min V", "Avg. V", "LFL%", "Std Dev"].map((name, idx) => {
  const { xValues, yValues } = generateNoisyLineData({
    datetime: true,
    seed: `seed-${idx}`,
    minY: 0.3 + 0.15 * idx,
    maxY: 1 + 0.15 * idx,
    numPoints: 20,
  });
  return {
    x: xValues,
    y: yValues,
    type: "scatter",
    mode: "line",
    name,
    yaxis: name === "LFL%" ? "y2" : "y",
    line: {
      width: 1,
    },
  };
});

export default function StackVoltage({ stacks }) {
  const panelRangeY = [0, 2];

  return (
    <div className={styles.container}>
      {stacks.map((s) => (
        <ChartCard
          key={s.id}
          data={data}
          title={s.name}
          id={s.id}
          yAxisRange={panelRangeY}
          margin={{ t: 5, r: 5, b: 35, l: 20 }}
          units={["V", "%"]}
          expandable
        />
      ))}
    </div>
  );
}
