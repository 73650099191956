import React, { useState } from "react";
import ResponsivePlot from "components/ResponsivePlot";
import { DatePicker, Button, Select } from "antd";
import dayjs from "dayjs";

import { ReactComponent as RefreshIcon } from "assets/refresh-icon.svg";

import styles from "./DVDT.module.less";

const { RangePicker } = DatePicker;

const defaultHistoricalRange = [dayjs().subtract(24, "hour"), dayjs()];

const refreshOptions = [
  {
    label: "Refresh disabled",
    value: 0,
  },
  {
    label: "5 sec",
    value: 5000,
  },
  {
    label: "15 sec",
    value: 15000,
  },
  {
    label: "min",
    value: 60000,
  },
  {
    label: "5 min",
    value: 300000,
  },
];

const historicalPresets = [
  {
    label: "Last 24 Hours",
    value: defaultHistoricalRange,
  },
  {
    label: "Last 7 Days",
    value: [dayjs().subtract(7, "day"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().subtract(30, "day"), dayjs()],
  },
];

const layout = {
  xaxis: {
    title: {
      text: "Elapsed Hours",
    },
    range: [0, 410],
  },
  yaxis: {
    title: {
      text: "dV/dt",
    },
    range: [1720, 1810],
    ticksuffix: " µV/h ",
  },
  margin: {
    t: 5,
    r: 25,
    b: 35,
    l: 90,
  },
};

const sampleX = [
  8, 12, 20, 22, 25, 28, 29, 34, 40, 48, 58, 60, 66, 75, 77, 84, 85, 86, 99, 105, 106, 107, 109, 111, 113,
];

const sampleY = [
  1770, 1770, 1772, 1773, 1772, 1772, 1772, 1773, 1772, 1772, 1772, 1773, 1772, 1772, 1772, 1772, 1773, 1773, 1774,
  1775, 1730, 1776, 1774, 1770, 1772,
];

const xValues = [...sampleX];
const xSteps = [];

for (let i = 1; i < sampleX.length; i++) {
  xSteps.push(sampleX[i] - sampleX[i - 1]);
}

let currentXValue = xValues[xValues.length - 1];
for (let i = 0; i < sampleX.length * 3; i++) {
  const nextXStep = xSteps[i % xSteps.length];
  currentXValue += nextXStep;
  xValues.push(currentXValue);
}

const yValues = [...sampleY];
const ySteps = [];

for (let i = 1; i < sampleY.length; i++) {
  ySteps.push(sampleY[i] - sampleY[i - 1]);
}

let currentYValue = yValues[yValues.length - 1];
for (let i = 0; i < sampleY.length * 3; i++) {
  const nextYStep = ySteps[i % ySteps.length];
  currentYValue += nextYStep;
  yValues.push(currentYValue);
}

export default function DVDT({ stacks }) {
  const [selectedRefresh, setSelectedRefresh] = useState(5000);

  const data = stacks.map((s, idx) => {
    const yValuesI = yValues.map((v) => v + 1 * idx);
    return {
      x: xValues,
      y: yValuesI,
      type: "scatter",
      mode: "line",
      name: s.id,
      line: {
        width: 1,
      },
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Local dV/dt</div>
          <div className={styles.rangePicker} style={{ width: 360 }}>
            <RangePicker showTime defaultValue={defaultHistoricalRange} presets={historicalPresets} />
          </div>
        </div>
        <div className={styles.plotContainer}>
          <ResponsivePlot
            data={data}
            layout={layout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Global dV/dt</div>
          <div className={styles.refreshContainer}>
            <Button shape="circle" className={styles.circleButton}>
              <RefreshIcon />
            </Button>
            <Select
              className={styles.refreshSelector}
              value={selectedRefresh}
              onChange={(newRefreshVal) => setSelectedRefresh(newRefreshVal)}
              options={refreshOptions.map((o) => ({
                value: o.value,
                label: o.value === 0 ? o.label : `Refresh every ${o.label}`,
              }))}
            />
          </div>
        </div>

        <div className={styles.content}>
          {stacks.map((s) => (
            <div key={s.id} className={styles.stackContainer}>
              <div>
                <div className={styles.name}>{s.name}</div>
                <div className={styles.id}>{s.id}</div>
              </div>
              <div className={styles.listItem}>
                <div className={styles.val}>{s.dVdT} </div>
                <div className={styles.unit}>µV/h</div>
              </div>
              <div className={styles.listItem}>
                <div className={styles.val}>{s.eol} </div>
                <div className={styles.unit}>Hrs to MPS</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
