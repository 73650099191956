import React from "react";
import ChartCard from "containers/StackDashboard/components/ChartCard/ChartCard";
import { generateNoisyLineData, generateStackId } from "utils/generate-data";

import styles from "./SubstackIndividual.module.less";

export default function SubstackIndividual() {
  const data = Array.from({ length: 10 }).map((_, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `seed-${idx}`,
      minY: 0.1 + 0.08 * idx,
      maxY: 1 + 0.08 * idx,
      numPoints: 20,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: `Cell ${(idx + 1).toString().padStart(2, "0")}`,
      line: {
        width: 1,
      },
    };
  });

  return (
    <div className={styles.container}>
      {Array.from({ length: 30 }).map((s, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx} className={styles.cardContainer}>
          <ChartCard
            height={220}
            data={data}
            title={`Substack ${idx + 1}`}
            id={generateStackId(`SS${idx}`)}
            yAxisRange={[0, 2]}
            margin={{ t: 5, r: 5, b: 35, l: 20 }}
            responsive={false}
            expandable
            units={["V"]}
          />
        </div>
      ))}
    </div>
  );
}
