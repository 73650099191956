/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { ReactComponent as StackIcon } from "assets/stack-detailed.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { generateRandomString, generateStackId } from "utils/generate-data";
import styles from "./SubstackGrid.module.less";

export default function SubstackGrid() {
  function generateStacksArray(count = 30) {
    const baseStack = {
      name: "Substack",
      type: 1,
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    };

    return Array.from({ length: count }, (_, index) => ({
      ...baseStack,
      name: `Substack ${index + 1}`,
      id: generateRandomString(3),
    }));
  }
  const stacks = generateStacksArray();

  return (
    <div className={styles.grid}>
      {stacks.map((s) => (
        <div key={s.id} className={styles.stackCard}>
          <div className={styles.header}>
            <div className={styles.headerSection}>
              <StackIcon width={18} height={18} />
              <div>{s.name}</div>
            </div>
            <div className={styles.headerSection}>
              ID <div className={styles.displayField}>{generateStackId(s.id)}</div>
            </div>
          </div>
          <div className={styles.content}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={styles.listContainer}>
                <div className={styles.listItem}>
                  <div>Max Cell V</div> <div className={styles.displayField}>1.96</div>
                </div>
                <div className={styles.listItem}>
                  <div>Avg Cell V</div> <div className={styles.displayField}>1.92</div>
                </div>
              </div>
              <div className={styles.listContainer}>
                <div className={styles.listItem}>
                  <div>Min Cell V</div> <div className={styles.displayField}>1.89</div>
                </div>
                <div className={styles.listItem}>
                  <div>Std Dev (V)</div> <div className={styles.displayField}>1</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
