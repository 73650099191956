import { convertToLocalTime } from "../../Plot/helpers/plot.helpers";

export const generateEventFilters = ({ panel }) => {
  const equipments = {};
  panel?.events.forEach((event) => {
    const { equipmentSlug } = event;
    if (!equipments[equipmentSlug]) {
      equipments[equipmentSlug] = {};
      equipments[equipmentSlug].eventTypes = [];
      equipments[equipmentSlug].equipmentSlug = equipmentSlug;
    }
    equipments[equipmentSlug].eventTypes.push(event.type);
  });
  return Object.values(equipments);
};

export const generateMeasureFilters = ({ panel }) => {
  const equipments = {};
  panel.measures.forEach((measure) => {
    const equipmentSlug = measure.equipment.slug;
    const measureSlug = measure.slug;
    const { organizationSlug } = measure;

    if (!equipments[equipmentSlug]) {
      equipments[equipmentSlug] = {};
      equipments[equipmentSlug].measureIds = [];
      // equipments[equipmentSlug].equipmentId = equipmentSlug;
      equipments[equipmentSlug].organizationSlug = organizationSlug;
    }
    equipments[equipmentSlug].measureIds.push(measureSlug);
  });
  return Object.values(equipments);
};

export const generateTraces = ({ telemetries, panel }) => {
  const time = telemetries.time.map((timestamp) => convertToLocalTime(timestamp));

  return telemetries.measures.map((measure) => {
    const measureMeta = panel.measures.find(
      (m) => m.slug === measure.slug && m.equipment.slug === measure.equipmentSlug
    );

    // Filter out null values
    const nonNullIndexes = measure.values
      .map((value, index) => (value !== null ? index : null))
      .filter((i) => i !== null);
    return {
      equipment: measureMeta?.equipment,
      measure: measureMeta,
      x: nonNullIndexes.map((index) => time[index]),
      y: nonNullIndexes.map((index) => measure.values[index]),
    };
  });
};

export const generateThresholds = (panel) =>
  panel.measures.map((m) => ({
    measureID: m.slug,
    min: m.thresholdMin,
    max: m.thresholdMax,
  }));

export const equalTraces = (array1, array2) =>
  array1.length &&
  array2.length &&
  array1.length === array2.length &&
  array1.every((el1) => array2.some((el2) => el1 === el2));
