import React from "react";
import ResponsivePlot from "components/ResponsivePlot";
import { generateBoxWhiskerData } from "utils/generate-data";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import stacks from "containers/StackDashboard/stackData";
import { ReactComponent as BoxWhiskerIcon } from "../../assets/box-whisker-icon.svg";

import styles from "./WhiskerChart.module.less";

const currentDensities = [6.79, 6.62, 6.83, 6.85, 6.92, 6.74, 6.78, 6.65];

export default function WhiskerChart({ mode, voltages }) {
  const { stackId } = useParams();
  const navigate = useNavigate();

  const generateSubstackNames = () => {
    const substacks = [];
    for (let i = 1; i <= 30; i++) {
      substacks.push(i);
    }
    return substacks;
  };

  const handleClick = (e) => {
    if (mode === "STACK" && e.points?.length === 1) {
      navigate(`/stack-dashboard/stacks/${e.points[0].pointNumber + 1}?view=overview`);
    }
  };

  const generateNames = () => {
    switch (mode) {
      case "STACK":
        return stacks.map((s) => `${s.name.slice(-2)}<br>ID: ${s.id}`);
      case "SUBSTACK":
        return generateSubstackNames();
      default:
        return generateSubstackNames();
    }
  };

  const xValues = generateNames();
  const averageVoltages = voltages.map((v) => v.avg);

  const barTooltipText = voltages.map((n) => `Cumulative runtime: ${n.runtime} hours`);

  const barXValues = generateNames();
  const barYValues = voltages.map((v) => v.runtime);

  const maxRuntime = Math.max(voltages.map((v) => v.runtime));

  const seed = mode === "STACK" ? "stacks" : `substack-${stackId}`;

  const traces = averageVoltages.map((v, idx) => ({
    x: 0,
    y: generateBoxWhiskerData({ average: v, boxSpread: 0.3, whiskerExtension: 1.6, n: 20, seed }),
    type: "box",
    name: generateNames()[idx],
    marker: {
      color: "#008b8a",
      opacity: 1,
      size: 6,
      line: {
        color: "#008B8A",
        width: 2,
      },
    },
    boxpoints: false,
    yaxis: "y2",
    yhoverformat: ".2f",
    hoverinfo: "y+name",
    hovertext: "",
    texttemplate: "%{value:.1f}",
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 28,
          fontSize: 12,
          marginTop: 14,
          zIndex: 5,
          paddingTop: 12,
        }}
      >
        <div style={{ display: "flex", gap: 8 }}>
          <div style={{ width: 15, height: 15, background: "#C7DDCC", margin: "auto" }} />
          <div>Cumulative Runtime</div>
          <Tooltip title="Cumulative runtime is all electrolyzing hours above 0.1 A/cm2" placement="topRight">
            <InfoCircleOutlined style={{ color: "#008b8a" }} />
          </Tooltip>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 3 }}>
            <BoxWhiskerIcon width={24} height={30} />
            <div style={{ display: "flex", flexDirection: "column", fontSize: 10, lineHeight: 1.3 }}>
              <div>max</div>
              <div>median</div>
              <div>min</div>
            </div>
          </div>
          <div style={{ paddingLeft: 5 }}>Voltage</div>
        </div>
      </div>
      {mode === "STACK" && (
        <div className={styles.boxesContainer}>
          {currentDensities.map((val) => (
            <div key={val} className={styles.upperBox}>{`${val} A/cm2`}</div>
          ))}
        </div>
      )}
      <div style={{ flex: 1, width: "100%", minHeight: 165, marginTop: mode === "STACK" ? -106 : -60 }}>
        <ResponsivePlot
          data={[
            ...traces,
            {
              x: barXValues,
              y: barYValues,
              type: "bar",
              xaxis: "x",
              yaxis: "y",
              width: 0.6,
              opacity: 1,
              marker: {
                color: "#C7DDCC",
              },
              name: "Cumulative Runtime",
              hovertemplate: barTooltipText.map((text) => `${text}<extra></extra>`),
            },
          ]}
          layout={{
            xaxis: {
              title: {
                text: mode === "STACK" ? "Stack" : "Substack #",
                standoff: 32,
              },
              tickvals: xValues,
              ticktext: xValues,
              fixedrange: true,
            },
            yaxis: {
              title: "Cumulative Runtime",
              side: "right",
              range: [1, maxRuntime * 1.2],
              fixedrange: true,
              titlefont: {
                size: 12,
              },
              showgrid: false,
              ticksuffix: " hrs",
            },
            yaxis2: {
              title: "Cell Voltage",
              fixedrange: true,
              range: [0, 2.2],
              overlaying: "y",
              side: "left",
              anchor: "free",
              titlefont: {
                size: 12,
              },
              ticksuffix: " V",
            },

            bargap: 0,
            barmode: "overlay",
            showlegend: false,
            margin: {
              l: 100,
              r: 100,
              b: 92,
              t: 70,
              pad: 20,
            },
          }}
          onClick={handleClick}
          config={{
            modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
            displaylogo: false,
            responsive: true,
          }}
        />
      </div>
    </div>
  );
}
